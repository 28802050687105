/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Text, Form, Input, Button, Icon, Message, LoadingIcon } from "basis";
import { useForgotPasswordContext, RESETSTEPS } from '../../contexts';
import { emailRegex } from "../../shared/helpers";
import { resetPwdContainerStyle, logoStyle, errorMsgStyle, pageLinkStyle, buttonSecondaryLinkStyle } from '../../shared/styles';
import { formStyle } from './ResetRequest.styles';

export const ResetRequest = ({ title, text, returnBtnText, returnBtnUrl }: ResetRequestProps) => {
  const { changeStep, email, changeEmail, requestChannel } = useForgotPasswordContext();
  const [ isValid, setIsValid] = useState<boolean>(false);
  const [ showError, setShowError] = useState<boolean>(false);
  const [ error, setError ] = useState<string>('');
  const [ showLoading, setShowLoading] = useState<boolean>(false);
  const genericError: string = 'There has been an error sending the email.  Please try again and if the issue continues, contact Latitude.';
  const initialValues: FormValues = {
    email: email
  };

  const handleSendCode = (): void => {
    setShowError(false);
    setShowLoading(true);
    const toSend = {
      "factor_type": "email",
      "profile": {
        "email": email
      }
    };

    fetch(`${process.env.REACT_APP_API_DOMAIN}/v1/users/recovery/otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'LFS-Request-Channel': requestChannel
      },
      body: JSON.stringify(toSend)
    })
    .then(response => {
      if (response.status === 201) {
        changeStep(RESETSTEPS.OTP);
      }
      else if (typeof response.json === 'function') {
        return response.json()
          .then(json => {
            setError(json.message ?? genericError);
            setShowError(true);
            setShowLoading(false);
          });
      }
      else {
        setError(response.statusText ?? genericError);
        setShowError(true);
        setShowLoading(false);
      }
    })
    .catch(error => {
      setError(error.message ?? genericError);
      setShowError(true);
      setShowLoading(false);
    });
  };

  const handleChange = (e): void => {
    changeEmail(e.value);
    setIsValid(emailRegex.test(e.value));
  };

  return (
    <div css={resetPwdContainerStyle}>
      <img css={logoStyle} src="images/latitude-logo.svg" alt="Latitude logo" />
      <Text as="h1" textStyle="heading4">{title}</Text>
      {showError &&
        <div css={errorMsgStyle}>
          <Message margin="4 0 0 0" severity="blocking" bg="secondary.pink.t30">{error}</Message>
        </div>
      }
      <Text margin="2 0 4 0">{text}</Text>
      <div css={formStyle}>
        <Form initialValues={initialValues}>
          <Input name="email" type="email" label="Email" onChange={handleChange} />
          {isValid && <Icon name="tick" color="conditional.positive.graphics" />}
        </Form>
      </div>
      <div css={pageLinkStyle}>
        {!!returnBtnText && !!returnBtnUrl &&
          <a href={returnBtnUrl} css={buttonSecondaryLinkStyle}>{returnBtnText}</a>
        }
        <Button onClick={handleSendCode} disabled={!isValid || showLoading}>
          {showLoading && <LoadingIcon color="white" />}
          {!showLoading && <span>Send Code</span>}
        </Button>
      </div>
    </div>
  );
};

type ResetRequestProps = {
  title: string;
  text: string;
  returnBtnText: string;
  returnBtnUrl: string;
};

type FormValues = {
  email: string;
};