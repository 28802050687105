/** @jsxImportSource @emotion/react */
import { ChangeEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { otpInputStyle } from './OtpInput.styles';

export const OtpInput = ({
  name = '',
  label = '',
  type = 'text',
  value = '',
  onChange,
  ...rest
}: InputProps) => {
  const uuid = uuidv4();

  return (
    <div css={otpInputStyle}>
      <label htmlFor={uuid}>{label}</label>
      <input
        id={uuid}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        aria-invalid="false"
        {...rest} />
    </div>
  );
};

type InputProps = {
  name: string,
  label: string,
  type?: string,
  value: string,
  onChange:(e: ChangeEvent<HTMLInputElement>) => void,
  [x:string]: any;
};