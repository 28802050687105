import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResetRequest, VerifyOTP, SetPassword, Success } from '../components';
import { OktaProvider, ForgotPasswordProvider, useForgotPasswordContext, RESETSTEPS } from '../contexts';
import { isPermittedUrl, stopXss } from '../shared/helpers';

export const ForgotPasswordPage = () => {
  document.title = 'Reset Password | Common Identity Services';
  
  return (
    <OktaProvider.Security>
      <ForgotPasswordProvider>
        <PageToLoad />
      </ForgotPasswordProvider>
    </OktaProvider.Security>
  );
};

const PageToLoad = () => {
  const navigate = useNavigate();
  const { oktaAuth } = OktaProvider.useOktaAuth();
  const { step, changeStep, recoveryToken, requestChannel, changeRequestChannel, returnLink, changeReturnLink, returnText, changeReturnText } = useForgotPasswordContext();
  const { state } = useLocation();
  const [ isSet, setIsSet ] = useState(false);
  const firstRender = useRef<boolean>(true);
  const retrievedSession = useRef<boolean>(false);

  useEffect(() => {
    if (!firstRender.current) {
      if (step === RESETSTEPS.REQUEST && !retrievedSession.current) {
        oktaAuth.session.get()
          .then(function(session) {
            retrievedSession.current = true;
            if (session.status === 'ACTIVE') {
              oktaAuth.tokenManager.getTokens()
              .then(tokens => {
                if (!tokens.accessToken) {
                  oktaAuth.token.getWithoutPrompt({
                    responseType: ['id_token', 'token']
                  })
                  .then(function(res) {
                    const resTokens = res.tokens;
                    oktaAuth.tokenManager.setTokens(resTokens);
                  })
                  .catch(function(err) {
                    console.log('Get tokens error:', err);
                  });
                }
              })
              .catch(err => {
                console.log('Retrieve tokens error:', err);
              });
            }
            else {
              // No session.
            }
          })
          .catch(function(err) {
            console.log('Get session error:', err);
          });
      }
    }
    else {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('state') === 'success') {
        // Get the values in sessionStorage if a redirect for logout was required.
        changeReturnLink(sessionStorage.getItem('returnLink') ?? '');
        changeReturnText(sessionStorage.getItem('returnText') ?? '');
        // Go straight to the success page.
        changeStep(RESETSTEPS.SUCCESS);
      }

      if (searchParams.get('set') === 'true') {
        setIsSet(true);
      }
  
      if ((state as locationState)?.set === 'true') {
        setIsSet(true);
      }
  
      let returnLinkUrlValue = searchParams.get('returnLink') ?? searchParams.get('loginLink');
      if (!isPermittedUrl(returnLinkUrlValue)) { returnLinkUrlValue = null; }
      if (returnLink === '' && returnLinkUrlValue) {
        changeReturnLink(stopXss(returnLinkUrlValue));
        // Set value in sessionStorage so it can be retrieved if a redirect for logout is required.
        sessionStorage.setItem('returnLink', stopXss(returnLinkUrlValue));
      }
  
      const returnTextUrlValue = searchParams.get('returnText');
      if (returnText === '' && returnTextUrlValue) {
        changeReturnText(stopXss(returnTextUrlValue));
        // Set value in sessionStorage so it can be retrieved if a redirect for logout is required.
        sessionStorage.setItem('returnText', stopXss(returnTextUrlValue));
      }
  
      const requestChannelValue = searchParams.get('rc');
      if (requestChannel === '' && requestChannelValue) {
        changeRequestChannel(stopXss(requestChannelValue));
      }

      firstRender.current = false;
    }
  }, [step, oktaAuth, state, requestChannel, returnLink, returnText, navigate, changeStep, changeReturnLink, changeReturnText, changeRequestChannel]);

  const setPasswordCall = (password: string) => {
    const toSend = {
      "recovery_token": recoveryToken,
      "credentials": {
        "password": {
          "value": password
        }
      }
    };

    return fetch(`${process.env.REACT_APP_API_DOMAIN}/v1/users/recovery/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'LFS-Request-Channel': requestChannel
      },
      body: JSON.stringify(toSend)
    });
  }; 

  // Set page values.
  const heading = isSet ? 'Set password' : 'Reset password';
  const successHeading = isSet ? <span>Password successfully<br />set up</span> : <span>Password successfully<br />changed</span>;
  const successText = isSet ? <span>Remember to use your new password next time you log in with your Latitude ID.<br /><br />You can close this window.</span> :
                              'Remember to use your new password next time you log in with your Latitude ID.';
  const requestText = isSet ? "Enter your email address and we'll send a code so that you can set your password." :
                              "Enter your email address and we'll send a code so that you can reset your password.";
  const returnBtnUrl = returnLink === '' ? null : returnLink;
  const returnBtnText = returnLink === '' ? null : returnText === '' ? 'Return' : `Return to ${returnText}`;
  
  // Hide the Return button after 15 mins when the Okta key expires.
  setTimeout(() => {
    changeReturnLink('');
  }, 900000);
  
  switch (step) {
    case 2:
      return <VerifyOTP title={heading}
                returnBtnText={returnBtnText}
                returnBtnUrl={returnBtnUrl} />;
    case 3:
      return <SetPassword title={heading}
                setPasswordCall={setPasswordCall}
                changeStep={changeStep}
                STEPS={RESETSTEPS}
                returnBtnText={returnBtnText}
                returnBtnUrl={returnBtnUrl} />;
    case 4:
      return <Success
                title={successHeading}
                text={successText}
                btnText={returnBtnText}
                btnUrl={returnBtnUrl} />;
    default:
      return <ResetRequest
                title={heading}
                text={requestText}
                returnBtnText={returnBtnText}
                returnBtnUrl={returnBtnUrl} />;
  }
};

type locationState = {
  set: string;
}
