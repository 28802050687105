import { Security, useOktaAuth } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

export const OktaSecurity = ({children}) => {
  const oktaAuth = new OktaAuth({
    issuer: `${process.env.REACT_APP_ISSUER}`,
    clientId: 'common-identity',
    scopes: ['openid', 'lfs:identity:manage_credential']
  });
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(
      toRelativeUrl(originalUri || '/', window.location.origin)
    );
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export const OktaProvider = {
  Security: OktaSecurity,
  useOktaAuth: useOktaAuth
};