import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage, ForgotPasswordPage, SetPasswordPage } from "./pages";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/forgot-password' element={<ForgotPasswordPage />} />
        <Route path='/set-password' element={<SetPasswordPage />} />
      </Routes>
    </Router>
  );
};

export default App;