import { css } from '@emotion/react';

export const otpFormStyle = css`
  display: flex;
  justify-content: space-between;
`;

export const linkButtonStyle = css`
  padding: 0;
  margin-left: 10px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #0046aa;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #0046aa80;
  transition: background-color 200ms ease-out 0s, border-bottom-color 200ms ease-out 0s;
  cursor: pointer;

  &:hover {
    background-color: #d8edff;
    border-bottom-color: #0046aa;
  }
`;

export const resendIconStyle = css`
  display: inline-block;
  height: 20px;
  margin-left: 10px;
  vertical-align: text-bottom;
`;