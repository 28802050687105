/** @jsxImportSource @emotion/react */
import { ReactElement } from 'react';
import { Text, Link } from "basis";
import { resetPwdContainerStyle } from '../../shared/styles';
import { imageStyle, textContainer, linkContainer } from './Success.styles';

export const Success = ({ title, text, btnText, btnUrl }: SuccessProps) => {
  return (
    <div css={resetPwdContainerStyle}>
      <img src="/images/success.svg" alt="Success" css={imageStyle} />
      <div css={textContainer}>
        {!!title &&
          <Text as="h1" textStyle="heading4" margin="4 0 2 0">{title}</Text>
        }
        <Text margin="0 0 4 0">{text}</Text>
      </div>
      {!!btnUrl &&
        <div css={linkContainer}>
          <Link
            href={btnUrl}
            appearance="primary-button"
            variant="blue-button"
            newTab={false}
          >
            {btnText}
          </Link>
        </div>
      }
    </div>
  );
};

type SuccessProps = {
  title?: ReactElement;
  text: string | ReactElement;
  btnText?: string;
  btnUrl?: string;
};