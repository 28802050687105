import { css } from '@emotion/react';

export const globalStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
  }

  body {
    min-height: 100%;
    background: #fff;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const visuallyHiddenStyle = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const pageContainerStyle = css`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: #0046aa;
`;

export const resetPwdContainerStyle = css`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  background-color: #fff;
  padding: 48px 20px;
  max-width: 540px;
  margin: auto;

  @media (min-width: 758px) {
    padding-left: 76px;
    padding-right: 76px;
  }
`;

export const logoStyle = css`
  width: 150px;
  margin-top: -10px;
  margin-bottom: 30px;
`;

export const errorMsgStyle = css`
  margin-top: 12px;
  margin-bottom: 8px;
`;

export const pageLinkStyle = css`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;

  a, button {
    cursor: pointer;

    &:last-child {
      margin-left: auto;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  svg {
    vertical-align: -3px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const buttonLinkStyle = css`
  display: inline-flex;
  min-height: 48px;
  margin: 0;
  padding: 0 24px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto',sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #fff;
  background-color: #006aff;
  border: 1px solid #006aff;
  border-radius: 4px;
  transition: background-color 150ms ease,color 150ms ease;
  text-decoration: none;
  outline: 0;
  cursor: pointer;

  &:hover:not(:disabled) {
    color: #fff;
    background-color: #0046aa;
  }
`;

export const buttonSecondaryLinkStyle = css`
  ${buttonLinkStyle}
  color: #006aff;
  background-color: transparent;
  margin-right: 10px;

  &:hover:not(:disabled) {
    color: #fff;
    background-color: #006aff;
  }

  &:focus {
    outline: 0;
    border-radius: 1px;
    box-shadow: 0 0 0px 4px #82c6ff;
  }

  @media (max-width: 500px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;