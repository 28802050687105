import { css } from '@emotion/react';

export const imageStyle = css`
  width: 100%;
  max-width: 100px;
  height: 100%;
  max-height: 100px;
`;

export const textContainer = css`
  max-width: 350px;
`;

export const linkContainer = css`
  max-width: 260px;
`;