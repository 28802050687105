/** @jsxImportSource @emotion/react */
import { useState, ChangeEvent } from "react";
import { css } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';
import { useOktaAuth } from "@okta/okta-react";
import { Text, Message, Button, Icon, LoadingIcon, VisuallyHidden } from "basis";
import { hasNumRegex, hasSymbolRegex, hasUpperCaseRegex, hasLowerCaseRegex } from "../../shared/helpers";
import { resetPwdContainerStyle, logoStyle, errorMsgStyle, pageLinkStyle, buttonSecondaryLinkStyle } from '../../shared/styles';
import { formStyle, ruleContainerStyle, ruleStyle, ruleCircleStyle } from './SetPassword.styles';

export const SetPassword = ({ title, setPasswordCall, changeStep, STEPS, returnBtnText, returnBtnUrl }: SetPasswordProps) => {
  const { oktaAuth } = useOktaAuth();
  const [ showError, setShowError ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>('');
  const [ showPassword, setShowPassword ] = useState<boolean>(false);
  const [ password, setPassword ] = useState<string>('');
  const [ showLoading, setShowLoading] = useState<boolean>(false);
  const [ enoughCharacters, setEnoughCharacters ] = useState<boolean>(false);
  const [ hasNumber, setHasNumber ] = useState<boolean>(false);
  const [ hasSymbol, setHasSymbol ] = useState<boolean>(false);
  const [ hasUpperLower, setHasUpperLower ] = useState<boolean>(false);
  const genericError: string = 'There has been an error saving the new password.  Please try again and if the issue continues, contact Latitude.';

  const endSessionAndSuccess = () => {
    oktaAuth.tokenManager.getTokens()
    .then(tokens => {
      if (tokens.idToken) {
        oktaAuth.signOut({
          postLogoutRedirectUri: `${window.location.origin}/forgot-password?state=success`
        });
      }
      else {
        changeStep(STEPS.SUCCESS);
      }
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value;

    if (value.length > 7) { setEnoughCharacters(true); }
    else { setEnoughCharacters(false); }

    if (hasNumRegex.test(value)) { setHasNumber(true); }
    else { setHasNumber(false); }

    if (hasSymbolRegex.test(value)) { setHasSymbol(true); }
    else { setHasSymbol(false); }

    if (hasUpperCaseRegex.test(value) && hasLowerCaseRegex.test(value)) { setHasUpperLower(true); }
    else { setHasUpperLower(false); }

    setPassword(e.currentTarget.value);
  };

  const handleShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  const handleSetPassword = (): void => {
    setShowPassword(false);
    setShowError(false);
    setShowLoading(true);

    
    setPasswordCall(password)
    .then(response => {
      if (response.status === 204) {
        endSessionAndSuccess();
      }
      else if (typeof response.json === 'function') {
        return response.json()
          .then(json => {
            setError(json.message ?? genericError);
            setShowError(true);
            setShowLoading(false);
          });
      }
      else {
        setError(response.statusText ?? genericError);
        setShowError(true);
        setShowLoading(false);
      }
    })
    .catch(error => {
      setError(error.message ?? genericError);
      setShowError(true);
      setShowLoading(false);
    });
  };

  const handleSubmit = (e): void => {
    e.preventDefault();
    if (passwordValid) {
      handleSetPassword();
    }
  };

  // Set the form values.
  const uuid = uuidv4();
  const inputType = showPassword ? "text" : "password";
  const iconType = showPassword ? "eye-hidden" : "eye-visible";
  const text = showPassword ? "Hide password" : "Show password";

  // Set if the password has passed validation.
  const passwordValid = enoughCharacters && hasNumber && hasSymbol && hasUpperLower;

  return (
    <div css={resetPwdContainerStyle}>
      <img css={logoStyle} src="images/latitude-logo.svg" alt="Latitude logo" />
      <Text as="h1" textStyle="heading4">{title}</Text>
      {showError &&
        <div css={errorMsgStyle}>
          <Message margin="4 0 0 0" severity="blocking" bg="secondary.pink.t30">{error}</Message>
        </div>
      }
      <Text margin="2 0 0 0">Enter your new password below.</Text>
      <Text margin="2 0 4 0" textStyle="body2"><b>Tip:</b> Don't select a password that is common, you have previously used or which includes part of your name.</Text>
      <form css={formStyle} noValidate onSubmit={handleSubmit}>
        <label htmlFor={uuid}>Create new password</label>
        <input id={uuid} type={inputType} value={password} onChange={handleChange} />
        <Button variant="icon" onClick={handleShowPassword}>
          <Icon name={iconType} color="grey.t75" />
          <VisuallyHidden>{text}</VisuallyHidden>
        </Button>
      </form>
      <div css={ruleContainerStyle}>
        <Rule valid={enoughCharacters} text="8 characters" />
        <Rule valid={hasUpperLower} text="1 upper + lower" />
        <Rule valid={hasNumber} text="1 number" />
        <Rule valid={hasSymbol} text="1 symbol" />
      </div>
      <div css={pageLinkStyle}>
        {!!returnBtnText && !!returnBtnUrl &&
          <a href={returnBtnUrl} css={buttonSecondaryLinkStyle}>{returnBtnText}</a>
        }
        <Button onClick={handleSetPassword} disabled={!passwordValid || showLoading}>
          {showLoading && <LoadingIcon color="white" />}
          {!showLoading && <span>Set password</span>}
        </Button>
      </div>
    </div>
  );
};

const Rule = ({ valid, text }: RuleProps) => {
  const colour = valid ? '#1a8450' : '#000';
  const icon = valid ? <TickSvg /> : <div css={ruleCircleStyle}></div>;
  return (
    <div css={ruleStyle}>
      {icon}
      <span css={css`color: ${colour};`}>{text}</span>
    </div>
  );
};

const TickSvg = () => {
  return (
    <svg width="16px" height="16px" viewBox="8 8 16 16" focusable="false" role="img">
      <path d="M13.749 18.129l5.985-6.787a1 1 0 011.505 1.317l-6.747 7.658a1 1 0 01-1.514-.01l-1.74-2.178a1 1 0 111.523-1.298l.988 1.298z" fill="#000000"></path>
    </svg>
  );
};

type SetPasswordProps = {
  title: string;
  setPasswordCall: (password: string) => Promise<any>;
  changeStep: (step: number) => void;
  STEPS: any;
  returnBtnText?: string;
  returnBtnUrl?: string;
};

type RuleProps = {
  valid: boolean;
  text: string;
};