import { css } from '@emotion/react';

export const formStyle = css`
  position: relative;

  label {
    display: block;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    height: 48px;
    margin: 0;
    padding: 0 16px;
    border: none;
    background-color: #f2f2f2;

    &:focus {
      outline: 0;
      border-radius: 1px;
      box-shadow: rgb(130 198 255) 0 0 0 4px;
    }
  }

  button {
    position: absolute !important;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
`;

export const ruleContainerStyle = css`
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
`;

export const ruleStyle = css`
  display: flex;
  margin-top: 5px;
  flex-basis: 40%;
  align-items: center;
  font-size: 12px;

  svg {
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 50%;
  }
`;

export const ruleCircleStyle = css`
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #f2f2f2;
`;