/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom';
import { Container } from "basis";
import { buttonLinkStyle } from '../shared/styles';

export const HomePage = () => {
  return (
    <Container bg="white" padding="6 8">
      <Link to="/forgot-password" css={buttonLinkStyle}>Forgot Password</Link>
    </Container>
  );
};