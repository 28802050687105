import React from 'react';

const RESETSTEPS = {
  REQUEST: 1,
  OTP: 2,
  SET: 3,
  SUCCESS: 4
};

const ForgotPasswordContext = React.createContext<ForgotPwdContextProps>({
  step: RESETSTEPS.REQUEST,
  setStep: (): void => {},
  email: '',
  setEmail: (): void => {},
  recoveryToken: '',
  setRecoveryToken: (): void => {},
  requestChannel: '',
  setRequestChannel: (): void => {},
  returnLink: '',
  setReturnLink: (): void => {},
  returnText: '',
  setReturnText: (): void => {}
});

const useForgotPasswordContext = () => {
  const {
    step, setStep,
    email, setEmail,
    recoveryToken, setRecoveryToken,
    requestChannel, setRequestChannel,
    returnLink, setReturnLink,
    returnText, setReturnText
  } = React.useContext(ForgotPasswordContext);

  const handleStepChange = value => {
    setStep(value);
  };

  const handleEmailChange = value => {
    setEmail(value);
  };

  const handleRecoveryTokenChange = value => {
    setRecoveryToken(value);
  };

  const handleRequestChannelChange = value => {
    setRequestChannel(value);
  };

  const handleReturnLinkChange = value => {
    setReturnLink(value);
  };

  const handleReturnTextChange = value => {
    setReturnText(value);
  };

  return {
    step: step,
    changeStep: handleStepChange,
    email: email,
    changeEmail: handleEmailChange,
    recoveryToken: recoveryToken,
    changeRecoveryToken: handleRecoveryTokenChange,
    requestChannel: requestChannel,
    changeRequestChannel: handleRequestChannelChange,
    returnLink: returnLink,
    changeReturnLink: handleReturnLinkChange,
    returnText: returnText,
    changeReturnText: handleReturnTextChange
  };
};

const ForgotPasswordProvider = ({ children }) => {
  const [ step, setStep ] = React.useState<number>(RESETSTEPS.REQUEST);
  const [ email, setEmail ] = React.useState<string>('');
  const [ recoveryToken, setRecoveryToken ] = React.useState<string>('');
  const [ requestChannel, setRequestChannel ] = React.useState<string>('');
  const [ returnLink, setReturnLink ] = React.useState<string>('');
  const [ returnText, setReturnText ] = React.useState<string>('');
  return (
    <ForgotPasswordContext.Provider value={{
      step, setStep,
      email, setEmail,
      recoveryToken, setRecoveryToken,
      returnLink, setReturnLink,
      returnText, setReturnText,
      requestChannel, setRequestChannel
    }}>
      {children}
    </ForgotPasswordContext.Provider>
  );
};

export { ForgotPasswordProvider, useForgotPasswordContext, RESETSTEPS };

type ForgotPwdContextProps = {
  step: number;
  setStep: (s: number) => void;
  email: string;
  setEmail: (s: string) => void;
  recoveryToken: string;
  setRecoveryToken: (s: string) => void;
  requestChannel: string;
  setRequestChannel: (s: string) => void;
  returnLink: string;
  setReturnLink: (s: string) => void;
  returnText: string;
  setReturnText: (s: string) => void;
};