import { useState, useEffect, useRef, ReactElement } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Loading, SetPassword, Success } from '../components';
import { OktaProvider, SetPasswordProvider, useSetPasswordContext, SETSTEPS } from '../contexts';

export const SetPasswordPage = () => {
  document.title = 'Set Password | Common Identity Services';
  
  return (
    <OktaProvider.Security>
      <SetPasswordProvider>
        <PageToLoad />
      </SetPasswordProvider>
    </OktaProvider.Security>
  );
};

const PageToLoad = () => {
  const navigate = useNavigate();
  const { oktaAuth } = OktaProvider.useOktaAuth();
  const { step, changeStep } = useSetPasswordContext();
  const [ showError, setShowError ] = useState<boolean>(false);
  const [ errorText, setErrorText ] = useState<string | ReactElement>('');
  const firstRender = useRef<boolean>(true);
  const retrievedSession = useRef<boolean>(false);
  const genericError: string = 'It seems there has been an error.';
  const contactLatMsg: string = 'If you continue to get this message, please contact Latitude for assistance.';

  useEffect(() => {
    if (!firstRender.current) {
      const checkPasswordSet = () => {
        fetch(`${process.env.REACT_APP_API_DOMAIN}/v2/users/statuses`, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + oktaAuth.getAccessToken()
          }
        })
        .then(response => {
          if (response.ok) {
            return response.json()
              .then(json => {
                if (json.status_initial_password_set === true) {
                  changeStep(SETSTEPS.ALREADYSET);
                }
                else {
                  changeStep(SETSTEPS.SET);
                }
              });
          }
          else {
            throw new Error(`${response.statusText ?? genericError}`);
          }
        })
        .catch(function(err) {
          setErrorText(<span>{err?.toString() ?? genericError}<br /><br />{contactLatMsg}</span>);
          setShowError(true);
        });
      };

      if (step === SETSTEPS.LANDING && !retrievedSession.current) {
        oktaAuth.session.get()
          .then(function(session) {
            retrievedSession.current = true;
            if (session.status === 'ACTIVE') {
              oktaAuth.tokenManager.getTokens()
              .then(tokens => {
                if (!tokens.accessToken) {
                  oktaAuth.token.getWithoutPrompt({
                    responseType: ['id_token', 'token']
                  })
                  .then(function(res) {
                    const resTokens = res.tokens;
                    oktaAuth.tokenManager.setTokens(resTokens);
                    checkPasswordSet();
                  })
                  .catch(function(err) {
                    console.log('Get tokens error:', err);
                    setErrorText(<span>It seems you dont have access to this app.<br /><br />Please contact Latitude for assistance.</span>);
                    setShowError(true);
                  });
                }
                else {
                  checkPasswordSet();
                }
              })
              .catch(err => {
                console.log('Retrieve tokens error:', err);
                setErrorText(<span>{err?.toString() ?? genericError}<br /><br />{contactLatMsg}</span>);
                setShowError(true);
              });
            }
            else {
              navigate('/forgot-password', { state: { set: 'true' } });
            }
          })
          .catch(function(err) {
            console.log('Get session error:', err);
            setErrorText(<span>{err?.toString() ?? genericError}<br /><br />{contactLatMsg}</span>);
            setShowError(true);
          });
      }
    }
    else {
      firstRender.current = false;
    }
  }, [step, oktaAuth, navigate, changeStep]);

  const setPasswordCall = (password: string) => {
    const toSend = {
        "password": {
            "value": password
        }
    };

    return fetch(`${process.env.REACT_APP_API_DOMAIN}/v2/users/credentials`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oktaAuth.getAccessToken()
      },
      body: JSON.stringify(toSend)
    });
  }; 

  // Set the text to be displayed on the loading screen.
  const loadingText = showError ? errorText : 'Checking password status...';
  
  switch (step) {
    case 2:
      return <Success text={
        <span>
          <br />
          Congratulations, you already have a password set up and are good to go.
          <br /><br />
          Forgotten your password? Reset it <Link to="/forgot-password">here</Link>
        </span>
      } />;
    case 3:
      return <SetPassword title="Set password"
                setPasswordCall={setPasswordCall}
                changeStep={changeStep}
                STEPS={SETSTEPS} />;
    case 4:
      return <Success
                title={<span>Password successfully<br />set up</span>}
                text={
                  <span>
                    Remember to use your new password next time you log in with your Latitude ID.
                    <br /><br />
                    You can close this window.
                  </span>
                } />;
    default:
      return <Loading text={loadingText} showError={showError} />
  }
};