import { css } from '@emotion/react';

export const formStyle = css`
  position: relative;

  svg {
    position: absolute;
    right: 8px;
    bottom: 8px;
    border: 1px solid #1a8450;
    border-radius: 50%;
  }
`;