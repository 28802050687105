/** @jsxImportSource @emotion/react */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Global } from '@emotion/react';
import App from './App';
import { BasisProvider, defaultTheme } from "basis";
import { globalStyles, pageContainerStyle } from './shared/styles';
import "typeface-montserrat";
import "typeface-roboto";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Global styles={globalStyles}></Global>
    <BasisProvider theme={defaultTheme}>
      <main css={pageContainerStyle}>
        <App />
      </main>
    </BasisProvider>
  </React.StrictMode>
);