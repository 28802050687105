/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Text, Message, Button, LoadingIcon } from "basis";
import { OtpInput } from '../../components';
import { useForgotPasswordContext, RESETSTEPS } from '../../contexts';
import { hasNumRegex } from "../../shared/helpers";
import { resetPwdContainerStyle, logoStyle, errorMsgStyle, pageLinkStyle, buttonSecondaryLinkStyle } from '../../shared/styles';
import { otpFormStyle, linkButtonStyle, resendIconStyle } from './VerifyOTP.styles';

export const VerifyOTP = ({ title, returnBtnText, returnBtnUrl }: VerifyOtpProps) => {
  const { changeStep, email, changeRecoveryToken, requestChannel } = useForgotPasswordContext();
  const [ showError, setShowError ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>('');
  const [ showLoading, setShowLoading] = useState<boolean>(false);
  const [ showResendLoading, setShowResendLoading] = useState<boolean>(false);
  const [ otpValues, setOtpValues ] = useState<FormValues>({
    firstDigit: '',
    secondDigit: '',
    thirdDigit: '',
    fourthDigit: '',
    fifthDigit: '',
    sixthDigit: ''
  });
  const genericError: string = 'There has been an error verifying the code.  Please try again and if the issue continues, contact Latitude.';

  const handleChange = (e): void => {
    const value = e.target.value;

    // Don't allow more than 1 character or anything but numbers.
    if (value !== '' && (value.length > 1 || !hasNumRegex.test(value))) {
      e.preventDefault();
    }
    else {
      // Focus the next input if this input has a value.
      if (value.length) {
        const form = e.target.form;
        const index = [...form].indexOf(e.target);
        form.elements[index + 1]?.focus();
      }

      // Update the value in state.
      let newValues = {...otpValues};
      newValues[e.target.name] = value;
      setOtpValues(newValues);
    }
  }

  const handleDelete = (e): void => {
    if (e.target.value === '' && (e.code === 'Delete' || e.code === 'Backspace')) {
      e.preventDefault();
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      form.elements[index - 1]?.focus();
    }
  };

  const handleVerify = (): void => {
    setShowError(false);
    setShowLoading(true);
    const toSend = {
      "factor_type": "email",
      "pass_code": otpValues.firstDigit + otpValues.secondDigit + otpValues.thirdDigit + otpValues.fourthDigit + otpValues.fifthDigit + otpValues.sixthDigit,
      "profile": {
        "email": email
      }
    };

    fetch(`${process.env.REACT_APP_API_DOMAIN}/v1/users/recovery/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'LFS-Request-Channel': requestChannel
      },
      body: JSON.stringify(toSend)
    })
    .then(response => {
      if (typeof response.json === 'function') {
        return response.json()
          .then(json => {
            if (json.recovery_token?.length) {
              changeRecoveryToken(json.recovery_token);
              changeStep(RESETSTEPS.SET);
            }
            else {
              setError(json.message ?? genericError);
              setShowError(true);
              setShowLoading(false);
            }
          });
      }
      else {
        setError(response.statusText ?? genericError);
        setShowError(true);
        setShowLoading(false);
      }
    })
    .catch(error => {
      setError(error.message ?? genericError);
      setShowError(true);
      setShowLoading(false);
    });
  };

  const handleResend = (): void => {
    setShowError(false);
    setShowResendLoading(true);
    const toSend = {
      "factor_type": "email",
      "profile": {
        "email": email
      }
    };

    fetch(`${process.env.REACT_APP_API_DOMAIN}/v1/users/recovery/otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'LFS-Request-Channel': requestChannel
      },
      body: JSON.stringify(toSend)
    })
    .then(response => {
      if (response.status === 201) {
        setShowResendLoading(false);
      }
      else if (typeof response.json === 'function') {
        return response.json()
          .then(json => {
            setError(json.message ?? genericError);
            setShowError(true);
            setShowResendLoading(false);
          });
      }
      else {
        setError(response.statusText ?? genericError);
        setShowError(true);
        setShowResendLoading(false);
      }
    })
    .catch(error => {
      setError(error.message ?? genericError);
      setShowError(true);
      setShowResendLoading(false);
    });
  };

  // Set the Verify button as disabled if all 6 digits haven't been entered yet.
  const digitsMissing = otpValues.firstDigit === '' || otpValues.secondDigit === '' || otpValues.thirdDigit === '' ||
                        otpValues.fourthDigit === '' || otpValues.fifthDigit === '' || otpValues.sixthDigit === '';

  return (
    <div css={resetPwdContainerStyle}>
      <img css={logoStyle} src="images/latitude-logo.svg" alt="Latitude logo" />
      <Text as="h1" textStyle="heading4">{title}</Text>
      {showError &&
        <div css={errorMsgStyle}>
          <Message margin="4 0 0 0" severity="blocking" bg="secondary.pink.t30">{error}</Message>
        </div>
      }
      <Text margin="2 0 4 0">We've sent a 6 digit code to your email address.<br />Please enter the 6 digit code below.</Text>
      <form css={otpFormStyle} method="POST" action="#" noValidate>
          <OtpInput name="firstDigit" label="First digit" value={otpValues.firstDigit} onChange={handleChange} />
          <OtpInput name="secondDigit" label="Second digit" value={otpValues.secondDigit} onChange={handleChange} onKeyDown={handleDelete} />
          <OtpInput name="thirdDigit" label="Third digit" value={otpValues.thirdDigit} onChange={handleChange} onKeyDown={handleDelete} />
          <OtpInput name="fourthDigit" label="Fourth digit" value={otpValues.fourthDigit} onChange={handleChange} onKeyDown={handleDelete} />
          <OtpInput name="fifthDigit" label="Fifth digit" value={otpValues.fifthDigit} onChange={handleChange} onKeyDown={handleDelete} />
          <OtpInput name="sixthDigit" label="Sixth digit" value={otpValues.sixthDigit} onChange={handleChange} onKeyDown={handleDelete} />
      </form>
      <Text margin="4 0 0 0">
        <span>Not received your code yet?</span>
        {showResendLoading && <span css={resendIconStyle}><LoadingIcon /></span>}
        {!showResendLoading && <button type="button" css={linkButtonStyle} onClick={handleResend}>Resend code</button>}
      </Text>
      <Text margin="2 0 0 0">If you still don't receive a code, please check your junk/spam folder and if you haven’t received it, ensure you have an account and the email address you entered is correct.</Text>
      <div css={pageLinkStyle}>
        {!!returnBtnText && !!returnBtnUrl &&
          <a href={returnBtnUrl} css={buttonSecondaryLinkStyle}>{returnBtnText}</a>
        }
        <Button onClick={handleVerify} disabled={digitsMissing || showLoading}>
          {showLoading && <LoadingIcon color="white" />}
          {!showLoading && <span>Verify</span>}
        </Button>
      </div>
    </div>
  );
};

type VerifyOtpProps = {
  title: string;
  returnBtnText?: string;
  returnBtnUrl?: string;
};

type FormValues = {
  firstDigit: string;
  secondDigit: string;
  thirdDigit: string;
  fourthDigit: string;
  fifthDigit: string;
  sixthDigit: string;
}; 