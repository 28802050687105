import React from 'react';

const SETSTEPS = {
  LANDING: 1,
  ALREADYSET: 2,
  SET: 3,
  SUCCESS: 4
};

const SetPasswordContext = React.createContext<SetPwdContextProps>({
  step: SETSTEPS.LANDING,
  setStep: (): void => {}
});

const useSetPasswordContext = () => {
  const { step, setStep } = React.useContext(SetPasswordContext);

  const handleStepChange = value => {
    setStep(value);
  };

  return {
    step: step,
    changeStep: handleStepChange
  };
};

const SetPasswordProvider = ({ children }) => {
  const [ step, setStep ] = React.useState<number>(SETSTEPS.LANDING);
  return (
    <SetPasswordContext.Provider value={{ step, setStep }}>
      {children}
    </SetPasswordContext.Provider>
  );
};

export { SetPasswordProvider, useSetPasswordContext, SETSTEPS };

type SetPwdContextProps = {
  step: number;
  setStep: (s: number) => void;
};