import DOMPurify from 'dompurify';

// Regular expressions | Email regex from https://emailregex.com/
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const hasNumRegex = /^(?=.*[0-9])/;
export const hasSymbolRegex = /^(?=.*[~`!@#$%^&*()--+={}[]|:;"'<>,.?\/_])/;
export const hasUpperCaseRegex = /^(?=.*[A-Z]).*$/;
export const hasLowerCaseRegex = /^(?=.*[a-z])/;

// Function to prevent Cross-Site Scripting (XSS) in URLs.
// eslint-disable-next-line
export const stopXss = url => {
  // If the URL contains the 'javascript:' protocol, return an empty string to prevent the URL being used.  Else return the sanitised URL.
  if (/j.*a.*v.*a.*s.*c.*r.*i.*p.*t.*:/gi.test(url)) { return ''; }
  return `${DOMPurify.sanitize(url)}`;
};

// Function to ensure the URL passed is a Latitude URL or localhost.
export const isPermittedUrl = url => /^.*\.?(latitudefinancial\.(com|co\.nz)|lfscnp\.com|localhost:\d{2,5}).*/.test(url);
