/** @jsxImportSource @emotion/react */
import { ReactElement } from "react";
import { Message, Text, LoadingIcon } from "basis";
import { css } from '@emotion/react';
import { resetPwdContainerStyle, errorMsgStyle } from '../../shared/styles';
import { loadingContainerStyle } from './Loading.styles';

export const Loading = ({ text, showError }: LoadingProps) => {
  return (
    <div css={css`${resetPwdContainerStyle} ${loadingContainerStyle}`}>
      {showError &&
        <div css={errorMsgStyle}>
          <Message severity="blocking" bg="secondary.pink.t30">{text}</Message>
        </div>
      }
      {!showError &&
        <>
          <LoadingIcon size="medium" />
          {!!text && <Text margin="4 0 0 0">{text}</Text>}
        </>
      }
    </div>
  );
};

type LoadingProps = {
  text?: string | ReactElement;
  showError?: boolean;
};