import { css } from '@emotion/react';
import { visuallyHiddenStyle } from '../../shared/styles';

export const otpInputStyle = css`
  width: 48px;

  label {
    ${visuallyHiddenStyle}
  }

  input {
    width: 100%;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    border: none;
    background-color: #f2f2f2;
    text-align: center;

    &:focus {
      outline: 0;
      border-radius: 1px;
      box-shadow: 0 0 0px 4px #82c6ff;
    }
  }
`;